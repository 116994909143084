.aboutSection {
  padding: 8rem 10rem;
  background: rgb(242, 242, 242);
}

.aboutContainer {
  max-width: 120rem;
  margin: auto;
  width: 92%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.aboutContainer p {
  font-size: 1.2rem;
}

.aboutHeading {
  margin-bottom: 4rem;
}

.aboutHeading h2::after {
  content: '';
  position: absolute;
  height: 5px;
  width: 3rem;
  background: #6187cc;
  margin-top: 4.5rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
}

.aboutHeading h2 {
  margin-bottom: 3rem;
  text-align: center;
  font-size: 2.5rem;
}

.aboutContent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.contentHeading {
  font-size: 1.65rem;
  margin-bottom: 1.2rem;
}

.aboutLeft p {
  margin-bottom: 1rem;
}

.contactBtn {
  display: inline-block;
  text-decoration: none;
  background: #6187cc;
  color: #fff;
  padding: 0.75rem 3rem;
  border-radius: 5px;
  margin-top: 2rem;
  font-weight: 700;
  font-size: 1.2rem;
}

.skills {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
}

.skill {
  font-weight: 600;
  letter-spacing: 0.5px;
  text-align: center;
  background: rgba(153, 153, 153, 0.2);
  color: rgba(0, 0, 0, 0.673);
  padding: 0.75rem 0.5rem;
  border-radius: 5px;
}

@media screen and (max-width: 768px) {
  .aboutSection {
    padding: 8rem 1.75rem;
  }

  .skills {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .aboutContent {
    display: flex;
    flex-direction: column;
  }
}
