* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Source Sans Pro', sans-serif;
}

html {
  scroll-behavior: smooth;
  height: 100%;
}

html,
body {
  box-sizing: border-box;
  position: relative;
  line-height: 1.5;
  overflow-x: hidden;
  overflow-y: initial;
  font-family: 'Source Sans Pro', sans-serif;
}

section {
  width: 100vw;
}

.btn:hover {
  transform: scale(1.05) translateY(-1px);
}

@media screen and (max-width: 768px) {
  html {
    font-size: 12px;
  }
}
