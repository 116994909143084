.contactSection {
  padding: 8rem 10rem;
  background: rgb(242, 242, 242);
  color: #fff;
  background: linear-gradient(to right, #6186ccbd, #6186cc95),
    url(../../images/common-bg.svg);
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
}

.contactContainer {
  max-width: 120rem;
  margin: auto;
  width: 92%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contactHeading {
  margin-bottom: 4rem;
}

.contactContainer p {
  font-size: 1.2rem;
}

.contactHeading h2::after {
  content: '';
  position: absolute;
  height: 5px;
  width: 3rem;
  background: #fff;
  margin-top: 4.5rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 5px;
}

.contactHeading h2 {
  margin-bottom: 3rem;
  text-align: center;
  font-size: 2.5rem;
}

.contactForm {
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
  background: #fff;
  padding: 4rem;
  max-width: 120rem;
  width: 95%;
  border-radius: 5px;
  margin: 2rem auto 0 auto;
}

.formSection {
  margin-bottom: 2rem;
}

.formLabel {
  color: #666;
  font-size: 1.1rem;
  letter-spacing: 1px;
  font-weight: 700;
  margin-bottom: 0.75rem;
  display: block;
  text-align: left;
}

.formInput {
  color: #333;
  padding: 1rem;
  width: 100%;
  border: 1px solid #ebebeb;
  font-size: 1.2rem;
  background: #f0f0f0;
  border-radius: 5px;
  font-weight: 600;
}

textarea {
  resize: none;
}

.submitBtn {
  display: inline-block;
  text-decoration: none;
  background: #6187cc;
  color: #fff;
  padding: 0.75rem 3rem;
  border-radius: 5px;
  font-weight: 700;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  box-shadow: 0 0 10px rgb(0 0 0 / 10%);
}

@media screen and (max-width: 768px) {
  .contactSection {
    padding: 8rem 1.75rem;
  }
}
