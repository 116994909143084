.heroSection {
  color: #fff;
  background: linear-gradient(to right, #6186ccbd, #6186cc95),
    url(../../images/common-bg.svg);
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  position: relative;
}

.heroContainer {
  width: 100%;
  height: 100%;
  padding-top: 90px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.heroContainer h1 {
  font-size: 4rem;
  text-transform: uppercase;
  margin-bottom: 3rem;
}

.heroInfo p {
  font-size: 1.5rem;
}

.heroInfo a {
  display: inline-block;
  text-decoration: none;
  background: #fff;
  color: #000;
  padding: 1rem 4rem;
  border-radius: 5px;
  margin-top: 3rem;
  font-weight: 700;
  font-size: 1.5rem;
}

.heroInfo a:hover {
  transform: translateY(-2px);
}

.heroSocials {
  border: 1px solid #fff;
  position: absolute;
  top: 45%;
  left: 0;
}

.heroSocial {
  border: 1px solid #fff;
  padding: 1rem;
  font-size: 2rem;
}

.heroSocial a {
  color: inherit;
}

.heroSocial a i:hover {
  transform: scale(1.1);
}

.scrollDown {
  position: absolute;
  top: 90%;
  left: 45%;
  right: 45%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
}

.downArrow {
  font-size: 2.5rem;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .heroSection {
    padding: 0 1.75rem;
  }

  .heroSocials {
    display: none;
  }

  .heroContainer h1 {
    font-size: 3rem;
  }

  .heroInfo p {
    font-size: 1.2rem;
  }

  .scrollDown p {
    display: none;
  }
}
