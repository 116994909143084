.navSection {
  position: fixed;
  width: 100vw;
  z-index: 1000;
  background: #fff;
  box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.2);
  height: 90px;
}

.navContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 5rem;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.logoContainer h1 a {
  text-decoration: none;
  color: #000;
  font-size: 1.8rem;
  cursor: pointer;
}

.logoContainer h1 a:hover {
  color: #6187cc;
}

.logoContainer h1 i {
  margin-right: 1rem;
  color: #6187cc;
  font-size: 1.8rem;
}

.bars {
  display: none;
}

.menuItems {
  display: flex;
}

.menuItem {
  list-style: none;
}

.menuItem a {
  text-decoration: none;
  padding: 2.2rem 3rem;
  display: inline-block;
  font-size: 1.1rem;
  color: #333;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  transition: color 0.3s;
  cursor: pointer;
}

.menuItem a:hover {
  color: #6187cc;
}

@media screen and (max-width: 768px) {
  .navContainer {
    padding: 1rem 2rem;
  }

  .logoContainer h1 a,
  .logoContainer h1 i {
    font-size: 1.9rem;
  }
}

@media screen and (max-width: 1100px) {
  .bars {
    display: block;
    font-size: 2.5rem;
    color: #6187cc;
    cursor: pointer;
  }

  .menuItems {
    display: none;
  }
}

@media (min-width: 760px) and (max-width: 1100px) {
  .logoContainer h1 a,
  .logoContainer h1 i {
    font-size: 2.2rem;
  }
}