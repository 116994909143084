.mobile-nav {
  width: 312px;
  height: 100%;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  position: fixed;
  right: 0;
  z-index: 999;
  transform: translateX(75%);
  opacity: 0;
  transition: all 0.5s;
}

@media (max-width: 320px) {
  .mobile-nav {
    width: 80vw;
  }
}

.visible {
  opacity: 1;
  transform: translateX(0%);
}

.mobile-nav-menu {
  padding-top: 110px;
  padding-bottom: 20px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.menu-list {
  height: 100%;
  width: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.list-item {
  display: flex;
  align-items: center;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  color: #333;
  font-size: 1.6rem;
  height: 100%;
  padding-left: 10vw;
}

.list-item:last-child {
  margin-bottom: 0;
}